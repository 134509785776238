<template>
  <div class="car-confirmation">
    <Header :isHeader="true" :back="true" :step="currentStepIndex" />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar />
      </template>
      <Title titleStyle="title" :text="translateString('vehicleWithServiceTitle')" />
      <CarInfo :justMobile="true" />
      <p class="align-left description">{{translateString('scheduledServicesMessage')}}</p>
      <p class="align-left description">{{translateString('scheduledServicesSecondMessage')}}</p>
      <hr />
      <div class="checkbox-container">
        <!-- Check que vem do serviço com os dados de item já agendaddos. -->
        <p class="align-left schedule-list-title">{{(this.markingService && this.markingService.length > 1)
            ?
            translateString('moreThanOneScheduledEvent')
            :
            translateString('oneScheduledEvent')}}</p>
        <LabelWithButtonContainer  v-for="(item, index) in markingService"
          :key="index"
          :id="'service-' + item.id"
          :value="item.id"
          :label="translateString('serviceDate') +
                ': ' +
                item.serviceDate +
                ' | ' +
                '  ' +
                translateString('serviceHour') +
                ': ' +
                item.serviceHour +
                ' | ' +
                '  ' +
                translateString('dealerName') +
                ': ' +
                item.dealerName
          "
          :buttonLabel="translateString('editSchedule')"
          name="service"
          @updateInput="
            goToScheduleEdit($event, item.dealerId, item)
          "
        />
      </div>
<!--      <p v-if="carCombo.comboSelectedByUser" class="sm-text-italic">
        {{ carCombo.comboLabel }}
      </p>-->
      <div class="row flex-wrap align-btn-mobile align-container-btn mb-2">
        <Button
          class="btn-tertiary elm-mobile"
          :label="translateString('changePlate')"
          @action="changePlate()"
        />
      </div>
      <template v-slot:footer>
        <Footer>
          <Button
            id="getCorrectToken"
            class="btn-primary align-btn-tertiary"
            :label="translateString('newSchedule')"
            @action="goToNewSchedule(4 )"
          />
          <Button
            class="btn-secondary elm-desktop"
            :label="translateString('changePlate')"
            @action="changePlate()"
          />
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<style lang="css" scoped>

.description {
  padding: 0.5rem 0;
}

.schedule-list-title {
  margin-top: 2rem;
  font-weight: bold;
}

</style>

<script>
import Button from "@/components/Buttons/Button.vue";
import LabelWithButtonContainer from "@/components/Checkbox/LabelWithButtonContainer.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Modal from "@/components/Modal/Modal.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import CarInfo from "@/components/UpdatedResume/CarInfo.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import { mapGetters, mapState } from "vuex";

import { formatNumber, translateString } from "@/functions/functions.js";
export default {
  name: "CarVersionSelect",
  components: {
    LabelWithButtonContainer,
    Button,
    CarInfo,
    Footer,
    Header,
    Modal,
    Sidebar,
    Title,
    Wrapper,
  },
  data() {
    return {
      markingServiceSelectedId: null,
      selectedCheckboxId: null,
      selectedHorsepower: null,
      carName: this.$store.state.carName,
      carKms: formatNumber(this.$store.state.carKms, "."),
      carData: this.$store.state.carByPlate,
      carCombo: this.$store.state.carComboSelected,
      carListedInfo: [],
      carKtypesInfo: [],
      ktypeInfo: this.$store.state.carByPlate.ktypeInfo,
      carBrand: this.$store.state.carByPlate.brand
        ? this.$store.state.carByPlate.brand
        : this.$store.state.carNF.brand,
      showModel: false,
      selectedCarKtype: null,
      isNewAppointment: false,
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    dealersReady() {
      return this.$store.state.dealersByBrandReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    ...mapState(["markingService"]),
    ...mapGetters(["getCurrentStepIndex", "getFakeCarCombos"]),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
  },
  created() {
    // So vou mostrar a tela se tiver um serviço agendado
    if (this.markingService.length <= 0) {
      this.$router.replace({ name: "CarConfirmation" });
    }
    //para poder mostrar a tela de agendamento de serviço apenas quando tivermos itens no objeto markingService
    if (this.markingService.length > 0) {
      this.$store.dispatch("setHasServiceSchedule", true);
    } else {
      this.$store.dispatch("setHasServiceSchedule", false);
    }
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
  },
  methods: {
    translateString,
    updateInput(value, id, ServiceScheduledSelected) {
      this.selectedCarKtype = value;
      this.selectCarKtypeInfo = value;
      this.$store.dispatch("selectCarKtype", value);
      this.$store.commit("SET_SELECTED_CAR_KTYPE", value);

      this.markingServiceSelectedId = ServiceScheduledSelected; // armazenando o ServiceScheduledSelected selecionado
      this.selectedCheckboxId = `combo-${id}`;

      // Atualizando isNewAppointment no Vuex store
      this.$store.commit("SET_IS_NEW_APPOINTMENT", id === "combo-4");

      // Vou disparar a ação ServiceScheduledSelected
      this.$store.dispatch(
        "setUpdateSelectedMarkingService",
        ServiceScheduledSelected
      );
    },
    goToScheduleEdit(value, id, ServiceScheduledSelected){
      this.updateInput(value, id, ServiceScheduledSelected);
      this.next();
    },
    goToNewSchedule(value){
      this.updateInput(value, value, undefined);
      this.next();
    },
    next() {
      const id = this.selectedCheckboxId;
      const selectedService = this.$store.state.markingServiceSelected;

      if (id === "combo-4") {
        if (this.$store.state.carByPlate.ktypes == null) {
          this.$router.push({ name: "CarConfirmation" });
        } else {
          this.$router.push({ name: "CarVersionSelect" });
        }
      } else if (selectedService) {
        if (this.$store.state.carByPlate.ktypes == null) {
          this.$router.push({ name: "CarConfirmation" });
        } else {
          this.$router.push({ name: "CarVersionSelect" });
        }
      } else {
        alert("Por favor, selecione um serviço antes de prosseguir.");
      }
    },

    changePlate() {
      this.$router.push({ name: "Homepage" });
    },
  },
  watch: {
    dealersReady() {
      if (this.dealersReady == true) {
        this.$router.push({ name: "CarConfirmation" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>