<template>
  <div class="row schedule-area">
    <label v-if="label" :for="id" class="sm-text">{{ label }}</label>
    <Button :class="'schedule-button'" :label="buttonLabel + value" @action="updateInput(value)"></Button>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";

export default {
  name: "LabelWithButtonContainer",
  components: {
    Button,
  },
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateInput(value) {
      this.$emit("updateInput", value);
    },
  },
};
</script>

<style lang="css" scoped>


@media screen and (max-device-width:700px) {
  .schedule-area{
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}

@media screen and (min-device-width:701px) {
  .schedule-area{
    display: grid;
    grid-template-columns: 4fr 1fr;
  }
}

.schedule-area:first-child{
  grid-column: 1;
}
.schedule-area:last-child{
  grid-column: 2;
}

.schedule-button{
  font-size: x-small;
}
</style>
