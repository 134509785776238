var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car-confirmation"},[_c('Header',{attrs:{"isHeader":true,"back":true,"step":_vm.currentStepIndex}}),_c('Wrapper',{attrs:{"ismain":true},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('Sidebar')]},proxy:true},{key:"footer",fn:function(){return [_c('Footer',[_c('Button',{staticClass:"btn-primary align-btn-tertiary",attrs:{"id":"getCorrectToken","label":_vm.translateString('newSchedule')},on:{"action":function($event){return _vm.goToNewSchedule(4 )}}}),_c('Button',{staticClass:"btn-secondary elm-desktop",attrs:{"label":_vm.translateString('changePlate')},on:{"action":function($event){return _vm.changePlate()}}})],1)]},proxy:true}])},[_c('Title',{attrs:{"titleStyle":"title","text":_vm.translateString('vehicleWithServiceTitle')}}),_c('CarInfo',{attrs:{"justMobile":true}}),_c('p',{staticClass:"align-left description"},[_vm._v(_vm._s(_vm.translateString('scheduledServicesMessage')))]),_c('p',{staticClass:"align-left description"},[_vm._v(_vm._s(_vm.translateString('scheduledServicesSecondMessage')))]),_c('hr'),_c('div',{staticClass:"checkbox-container"},[_c('p',{staticClass:"align-left schedule-list-title"},[_vm._v(_vm._s((this.markingService && this.markingService.length > 1) ? _vm.translateString('moreThanOneScheduledEvent') : _vm.translateString('oneScheduledEvent')))]),_vm._l((_vm.markingService),function(item,index){return _c('LabelWithButtonContainer',{key:index,attrs:{"id":'service-' + item.id,"value":item.id,"label":_vm.translateString('serviceDate') +
                ': ' +
                item.serviceDate +
                ' | ' +
                '  ' +
                _vm.translateString('serviceHour') +
                ': ' +
                item.serviceHour +
                ' | ' +
                '  ' +
                _vm.translateString('dealerName') +
                ': ' +
                item.dealerName,"buttonLabel":_vm.translateString('editSchedule'),"name":"service"},on:{"updateInput":function($event){return _vm.goToScheduleEdit($event, item.dealerId, item)}}})})],2),_c('div',{staticClass:"row flex-wrap align-btn-mobile align-container-btn mb-2"},[_c('Button',{staticClass:"btn-tertiary elm-mobile",attrs:{"label":_vm.translateString('changePlate')},on:{"action":function($event){return _vm.changePlate()}}})],1)],1),_c('Modal',{attrs:{"msg":_vm.callError,"isActive":_vm.callError != ''}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }